.root {
  margin-top: 0px;
  overflow: hidden;
  margin-bottom: 20px;

  .partner_info {
    width: 100%;
    height: 88%;
    margin: auto;
    display: flex;
    background-color: rgba(0, 0, 0, 0.453);
    position: fixed;
    top: 100px;
    z-index: 10;
    .partner_info_div {
      position: relative;
      margin: auto;
      display: flex;
      width: 700px;
      height: 300px;
      border-radius: 10px;
      background-color: white;
      @media (max-width: 1000px) {
        width: 90%;
        height: 400px;
      }
      .close_partner_info {
        position: absolute;

        margin: auto;
        right: 10px;
        top: 10px;
        width: 40px;
        height: 40px;
        background-color: rgb(219, 41, 41);
        border: thin solid gray;
        border-radius: 10px;
        font-size: 30px;
        color: white;
      }
      .partner_info_text_div {
        margin: auto;
        text-align: center;
        font-size: 23px;
        font-family: nunito;
        line-height: 45px;
        padding-left: 5px;
        padding-right: 5px;
        @media (max-width: 1000px) {
          font-size: 22px;
        }
        @media (max-width: 530px) {
          font-size: 18px;
        }
        .partner_name {
          border-bottom: thin solid rgb(219, 41, 41);
          color: rgb(219, 41, 41);
        }
        .partner_span {
          // color: grey;
        }
      }
    }
  }

  .title_block {
    width: 95%;
    height: 50%;
    margin-bottom: 40px;
    min-width: 600px;
    // background-color: blue;
    display: flex;
    div:first-child {
      display: flex;
      margin-top: 38px;
      background-color: rgb(219, 41, 41);
      width: 47%;
      height: 59px;
      z-index: -2;
      justify-content: end;
      @media (max-width: 2500px) {
        width: 40%;
      }
      @media (max-width: 1375px) {
        width: 40%;
        height: 50px;
      }
      @media (max-width: 1000px) {
        width: 43%;
        height: 40px;
      }
      p {
        font-size: 38px;
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 90px;
        font-family: 'Jura';
        font-weight: 500;
        @media (max-width: 1375px) {
          position: absolute;
          margin-right: 42px;
          font-size: 40px;
        }
        @media (max-width: 1000px) {
          position: absolute;
          margin: auto;
          margin-right: 35px;
          margin-top: 5px;
          font-size: 24px;
        }
      }
    }
    div:last-child {
      position: relative;
      margin-top: 47.1px;
      margin-left: -21px;
      background-color: white;
      border: thin solid rgb(219, 41, 41);
      width: 40.1px;
      height: 40.1px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: -1;
      @media (max-width: 1375px) {
        width: 34px;
        height: 34px;
        margin-top: 45px;
        margin-left: -18px;
      }
      @media (max-width: 1000px) {
        width: 26px;
        height: 26px;
        margin-top: 45px;
        margin-left: -14px;
      }
    }
  }
  .img_partners_img {
    margin: auto;
    // border: thin solid gray;
    width: 82%;
    max-width: 1700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    @media (max-width: 1375px) {
      max-width: 1400px;
    }
    @media (max-width: 1000px) {
      width: 96%;
      max-width: 600px;
    }
  }
  .partner_img:hover {
    box-shadow: 0px 1px 10px grey;
  }
}
.partner_img {
  margin: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 13%;
  border: thin solid rgb(228, 228, 228);
  cursor: pointer;
  @media (max-width: 1000px) {
    width: 23%;
  }
}
