.root {
  margin-top: 0px;
  display: flex;
  flex-direction: column-reverse;
  //  max-width: 2000px;
  margin: auto;
  @media (max-width: 1000px) {
    //    margin-top: -22px;
  }
  // height: 150px;
  .img_circles {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    //border: thin solid grey;
    // background-color: aqua;
    .circle {
      cursor: pointer;
      width: 18px;
      margin: 4px;

      height: 18px;
      text-align: center;
      border: thin solid grey;
      border-radius: 100px;
      @media (max-width: 1000px) {
        width: 12px;
        height: 12px;
      }
    }
    .circle_active {
      cursor: pointer;
      width: 18px;
      margin: 4px;
      background-color: rgb(219, 41, 41);
      height: 18px;
      text-align: center;
      border: thin solid grey;
      border-radius: 100px;
      @media (max-width: 1000px) {
        width: 12px;
        height: 12px;
      }
    }
  }
  .carousel {
    position: relative;
    //min-height: 200px;
    //max-width: 100%;
    width: 100%;
    max-width: 2000px;
    margin: auto;
    //display: flex;
    //flex-direction: row;
    user-select: none;
    // border: thin solid rgb(167, 167, 167);
    box-shadow: grey 0px 4px 10px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .leftWall {
      background-color: white;
      width: 5px;
      height: 100%;
      position: absolute;
      z-index: 5000;
    }
    .rightWall {
      background-color: white;
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0px;
      z-index: 5000;
    }
    .arrow_left {
      background-color: rgba(128, 128, 128, 0.271);
      cursor: pointer;
      width: 3%;
      position: absolute;
      fill: grey;
      top: 40%;
      left: 10px;
      border: thin solid grey;
      border-radius: 10px;
      z-index: 1;
      @media (max-width: 1000px) {
        width: 3%;
        margin-top: auto;
        margin-bottom: auto;
      }
      .arrow_svg {
        -webkit-transform: rotateX(180deg);
        transform: rotateX(180deg);
      }
    }
    .arrow_right {
      background-color: rgba(128, 128, 128, 0.271);
      cursor: pointer;
      width: 3%;
      position: absolute;
      fill: grey;
      top: 40%;
      right: 10px;
      border: thin solid grey;
      border-radius: 10px;
      z-index: 1;
      @media (max-width: 1000px) {
        width: 3%;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .all_img_div {
      height: 100%;
      display: flex;

      transition: translate;
      transition-property: transform;
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;
      margin: auto;
      .slider_img_div {
        display: flex;
        margin: auto;
        min-width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 650px;
        max-width: 2000px;
        // border: thin dotted cornflowerblue;
        .slider_img {
          margin: auto;
          max-width: 100%;
          max-height: 450px;

          @media (max-width: 1000px) {
            max-height: 220px;
          }
        }
      }
    }
    .all_img_div_moment {
      height: 100%;
      display: flex;
      margin: auto;
      max-width: 2000px;
      transition: translate;
      transition-property: transform;
      transition-duration: 0ms;
      transition-timing-function: ease-in-out;

      .slider_img_div {
        display: flex;
        margin: auto;
        min-width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 650px;
        max-width: 2000px;
        // border: thin dotted cornflowerblue;
        .slider_img {
          margin: auto;
          max-width: 100%;
          max-height: 450px;
          @media (max-width: 1000px) {
            max-height: 220px;
          }
        }
      }
    }
  }

  img {
    //  width: 100%;
    // border: thin solid lightgrey;
  }
  div {
    //margin-top: 10px;
    // display: flex;
    //flex-direction: row;
    //justify-content: center;
    // align-items: center;
    p {
      // margin: 4px;
      // width: 20px;
      // height: 20px;
      // border-radius: 100px;
      // border: thin solid grey;
    }
  }
}
