.root {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: aqua;

  .map_div {
    display: flex;
    position: relative;
    max-width: 1200px;
    min-width: 1200px;

    border: thin solid lightgrey;
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column-reverse;
    @media (max-width: 1200px) {
      min-width: 100%;
      max-width: 1000px;
    }
    @media (max-width: 1000px) {
      min-width: 98%;
      flex-direction: column-reverse;
    }

    .map_info_div {
      display: flex;
      flex-direction: column;
      min-width: 100%;
      width: 600px;
      @media (max-width: 1000px) {
        width: 98%;
      }
      //  background-color: aqua;
      h2 {
        font-family: nunito;
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 50px;
        margin: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        @media (max-width: 1000px) {
          font-size: 20px;
          font-weight: 500;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      p {
        font-family: nunito;
        font-size: 20px;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding-bottom: 20px;
        @media (max-width: 1000px) {
          font-size: 16px;
          padding-bottom: 20px;
        }
      }
    }
  }
}
