.root {
  position: relative;
  margin: auto;
  margin-bottom: 100px;
  width: 80%;
  padding: 50px;
  display: flex;
  justify-content: center;
  //min-height: 500px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0%;
    padding-top: 50px;
  }

  .items_place {
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    //min-height: 500px;
    //  border: thin solid grey;
    .empty_cart_message {
      font-family: nunito;
      border-bottom: thin solid grey;
      text-align: center;
      padding-bottom: 7px;
      padding-left: 200px;
      padding-right: 200px;
      font-size: 23px;
    }
  }
  .itog_info {
    margin: auto;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
    font-family: nunito;
    font-weight: 400;
    border-bottom: thin solid rgb(174, 174, 174);
    margin-top: 15px;
    margin-bottom: 10px;
    @media (max-width: 1000px) {
      margin-top: 0px;
      font-size: 22px;
    }
  }
  .bayBTN {
    margin: auto;
    cursor: pointer;
    width: 230px;
    height: 50px;
    border-radius: 10px;
    background-color: rgb(219, 41, 41);
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    margin-top: 10px;
    @media (max-width: 1000px) {
      height: 45px;
      width: 200px;
    }
    p {
      font-family: jura;
      font-weight: 500;
      font-size: 22px;
      color: white;
      @media (max-width: 1000px) {
        font-size: 20px;
      }
    }
  }
}
//////////////////////////////////////////////
///
///
///
///
///
.order_confirm_div {
  position: fixed;

  display: flex;
  width: 100%;
  height: 100%;

  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.43);
  top: 40px;
  left: 0px;

  .conf_div {
    overflow: auto;

    flex-direction: row;
    flex-wrap: wrap;
    // background-color: antiquewhite;
    background-color: white;
    position: relative;
    border-radius: 10px;
    margin: auto;
    // margin-top: 64px;
    width: 900px;
    max-width: 900px;
    height: 84%;
    max-height: 800px;
    display: flex;

    align-items: center;
    @media (min-height: 1000px) {
      margin-top: 90px;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      max-width: 98%;
      margin-top: 60px;
      min-height: 0%;
      height: 84%;
      max-height: 10000px;
      flex-wrap: nowrap;
      overflow: auto;
      padding-top: 5px;
    }
    .rules {
      margin: auto;

      width: 34%;
      @media (max-width: 1000px) {
        width: 90%;
        max-width: 600px;
      }
      // background-color: aqua;
      //height: fit-content;
      p {
        text-align: justify;
        font-family: nunito;
        font-size: 18px;
        @media (max-width: 1000px) {
          font-size: 16px;
          text-align: center;
        }
      }
    }
    .itogo {
      margin: auto;
      width: 90%;

      p {
        text-align: center;
        font-family: nunito;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 20px;
        padding-bottom: 10px;
        border-bottom: thin solid lightgrey;
        @media (max-width: 1000px) {
          font-size: 18px;
        }
      }
    }
    .form_conf_ok {
      box-shadow: 0px 3px 6px grey;
      // background-color: antiquewhite;
      margin: auto;
      border: thin solid lightgrey;
      width: 50%;
      display: flex;
      padding: 20px;
      flex-direction: column;
      justify-content: end;
      align-items: end;
      @media (max-width: 1000px) {
        min-width: 300px;
        max-width: 300px;
      }
      .inp_div {
        input {
          width: 300px;
          height: 25px;
          margin: 10px;
          font-size: 20px;
          font-family: nunito;
          text-align: center;
          @media (max-width: 1000px) {
            min-width: 275px;
            max-width: 275px;
          }
        }
        label {
          color: rgb(219, 41, 41);
          font-size: 20px;
          font-weight: bold;
          font-family: nunito;
          @media (max-width: 1000px) {
            font-size: 18px;
          }
        }
      }
      .check_div {
        margin-top: 10px;
        display: flex;
        border-top: thin solid lightgrey;
        p {
          color: rgb(219, 41, 41);
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          font-family: nunito;
        }
        input {
          min-width: 20px;
          //  height: 20px;
        }
      }
    }
    .close_ok_btn_div {
      width: 100%;
      min-height: 40px;
      position: relative;
      // background-color: antiquewhite;
      .close_ok_btn {
        position: absolute;

        display: flex;
        right: 3%;

        top: 5%;

        //border: thin solid grey;
        border-radius: 10px;
        background-color: rgb(219, 41, 41);
        color: white;
        width: 35px;
        height: 35px;

        cursor: pointer;
        p {
          font-family: nunito;
          font-size: 30px;
          margin: auto;
          margin-top: -5px;
          height: fit-content;
          //background-color: aliceblue;
        }
      }
    }
    .confirm_ok_btn {
      display: flex;

      left: 0%;
      right: 0%;
      border: thin solid grey;
      border-radius: 10px;
      background-color: rgb(219, 41, 41);
      color: white;
      width: fit-content;
      padding: 10px;

      cursor: pointer;

      font-family: nunito;
      font-size: 20px;
      margin: auto;
      margin-top: -3px;
      height: fit-content;
      @media (max-width: 1000px) {
        margin-bottom: 50px;
      }
      // margin-bottom: 50px;
      p {
        //background-color: aliceblue;
      }
    }
  }
}
