.root {
  width: 800px;
  //max-height: 50px;

  margin: auto;
  margin-top: 10px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -10;
  @media (max-width: 1375px) {
    margin-top: 9px;
    height: 51px;
  }
  @media (max-width: 1000px) {
    height: 39px;
    width: 300px;
    max-width: 300px;
    margin-top: 0px;
  }

  .header_background {
    display: flex;
    background-color: rgb(219, 41, 41);
    width: 1600px;
    min-width: 1000px;
    margin: auto;

    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 41px;
    justify-content: center;
    align-items: center;
    @media (max-width: 1375px) {
      height: 32px;
      min-width: 880px;
    }
    @media (max-width: 1000px) {
      max-height: 19px;
      width: 300px;
      min-width: 320px;
    }
    label {
      margin-left: 250px;
      margin-right: 250px;
      color: white;
      font-family: 'Jura';
      font-weight: 500;
      font-size: 35px;
      text-align: center;
      @media (max-width: 1375px) {
        font-size: 40px;
      }
      @media (max-width: 1000px) {
        margin-top: -2px;
        font-size: 29px;
        font-weight: 200px;
        min-width: 300px;
      }
    }
  }
  .left_romb {
    position: absolute;
    left: -15.1%;
    width: 41px;
    height: 41px;
    top: 9px;
    background-color: white;
    border: thin solid rgb(219, 41, 41);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 10;
    @media (max-width: 1375px) {
      width: 34px;
      height: 34px;
      left: -58px;
      top: 8px;
    }
    @media (max-width: 1000px) {
      width: 25.5px;
      height: 25.5px;
      top: 5.8px;
      left: -23px;
    }
  }
  .right_romb {
    position: absolute;
    right: -15.2%;
    width: 41px;
    height: 41px;

    background-color: white;
    border: thin solid rgb(219, 41, 41);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 10;
    @media (max-width: 1375px) {
      width: 34px;
      height: 34px;
      left: 822px;
    }
    @media (max-width: 1000px) {
      width: 25.5px;
      height: 25.5px;
      left: 296px;
    }
  }
}
