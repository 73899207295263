.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  // height: 100px;
  // background-color: aqua;

  .OnasB_div {
    width: 50%;
    min-width: 1200px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
      min-width: 1000px;
      max-width: 1000px;
    }
    @media (max-width: 1000px) {
      min-width: 800px;
      max-width: 800px;
    }
    @media (max-width: 800px) {
      min-width: 100%;
    }

    h2 {
      font-family: nunito;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 50px;
      @media (max-width: 1000px) {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 400;
        margin-bottom: 20px;
      }
    }
    .img_info_div {
      display: flex;
      flex-direction: row;
      //  background-color: blue;
      margin: 2px;
      @media (max-width: 1000px) {
        flex-direction: column;
        border-bottom: thin solid lightgrey;
        padding-bottom: 10px;
        padding-top: 10px;
      }
      .img_div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        min-width: 200px;
        @media (max-width: 1000px) {
          width: 70px;
          min-width: 70px;
        }
        //background-color: red;
        img {
          width: 70px;
          @media (max-width: 1000px) {
            width: 40px;
            padding-left: 30px;
          }
        }
      }
      .info_div {
        // background-color: aquamarine;
        display: flex;
        width: 100%;
        display: flex;
        padding-right: 150px;
        @media (max-width: 1000px) {
          padding-right: 50px;

          width: 100%;
        }
        p {
          font-family: nunito;
          font-size: 20px;
          text-align: justify;
          justify-content: center;
          align-items: center;
          @media (max-width: 1000px) {
            font-size: 16px;
            padding-left: 30px;
            padding-right: 30px;
          }
        }
        ul {
          font-family: nunito;

          font-size: 20px;
          margin-bottom: 2px;
          text-align: justify;
          justify-content: center;
          align-items: center;
          @media (max-width: 1000px) {
            font-size: 16px;
            font-weight: bold;
            padding-right: 30px;
            text-align: justify;
          }
          li {
            font-family: nunito;
            font-size: 20px;
            text-align: justify;
            justify-content: center;
            align-items: center;
            @media (max-width: 1000px) {
              font-size: 16px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
