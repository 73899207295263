.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  // height: 100px;
  padding-bottom: 50px;
  // background-color: aqua;
  .OnasP_div {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    min-width: 1200px;
    max-width: 1200px;
    justify-content: center;
    align-items: center;
    @media (max-width: 1200px) {
      min-width: 1000px;
      max-width: 1000px;
    }
    @media (max-width: 1000px) {
      min-width: 100%;
      width: 100%;
    }

    h2 {
      font-family: nunito;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 50px;
      @media (max-width: 1000px) {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    p {
      font-family: nunito;
      font-size: 20px;
      text-align: justify;
      justify-content: center;
      align-items: center;
      padding-left: 100px;
      padding-right: 100px;
      @media (max-width: 1000px) {
        font-size: 16px;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    .img_info_div {
      display: flex;
      flex-direction: row;
    }
    img {
      width: 60%;
      min-width: 20px;
      @media (max-width: 1000px) {
        width: 100%;
        min-width: 20px;
      }
    }
  }
}
