.root {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  display: flex;

  width: 100%;
  .codiv {
    position: relative;
    margin: auto;
    width: 100%;
    height: 1px;
    max-width: 1800px;

    //background-color: aqua;
    .call_order_div_max {
      position: absolute;
      display: flex;
      right: 0px;
      top: -500px;
      display: flex;
      width: 400px;
      height: 500px;
      overflow: hidden;

      .call_order_btn_hide {
        display: none;
      }
      @keyframes slideShow {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0);
          z-index: 100;
        }
      }

      .call_order_panel_show {
        border: 2px solid lightgrey;
        width: 400px;
        height: 96%;
        z-index: 200px;
        border-radius: 10px;
        margin: auto;
        box-shadow: 0px 3px 6px lightgrey;
        animation: slideShow 0.3s linear;
        background-color: gray;
        overflow: hidden;
        @media (max-width: 1000px) {
          width: 350px;
          margin-left: 40px;
        }
        .close_call_order_panel_btn {
          position: absolute;
          display: flex;
          margin-left: 358px;
          margin-top: 4px;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          color: white;
          font-size: 25px;
          background-color: rgb(219, 41, 41);
          cursor: pointer;
          z-index: 2000;
          border-radius: 5px;
          border: thin solid lightgray;
          @media (max-width: 1000px) {
            margin-left: 315px;
          }
        }
        .call_order_info {
          border-top: thin solid lightgray;
          padding-top: 15px;
          font-family: nunito;
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 18px;

          span {
            font-size: 19px;
            font-weight: 500;
            color: rgb(219, 41, 41);
          }
        }
        .call_order {
          border-top: thin solid lightgray;
          border-bottom: thin solid lightgray;
          display: flex;
          flex-direction: column;
          background-color: white;

          padding-bottom: 100px;
          margin-top: 40px;
          .call_order_info_text {
            margin-top: 0px;
            text-align: center;
            font-family: jura;
            font-size: 26px;
            padding-bottom: 5px;
            background-color: rgb(219, 41, 41);
            color: white;
            font-weight: 500;
          }
          .call_order_info_text2 {
            margin-top: 0px;
            text-align: justify;
            font-family: nunito;
            font-size: 16px;
            padding-bottom: 5px;
            padding-left: 20px;
            padding-right: 20px;
            font-weight: 500;
            @media (max-width: 1000px) {
              font-size: 16px;
            }
          }
          .call_order_conf_div {
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .call_order_info_text3 {
              font-family: nunito;
              text-align: center;
              font-size: 18px;
              @media (max-width: 1000px) {
                font-size: 16px;
              }
            }
          }
          .tel_div {
            margin-top: -10px;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: start;
            // margin-left: -90px;
            .call_order_info_text2 {
              font-family: nunito;
              font-size: 18px;
              font-weight: 500;
              margin-top: 20px;
            }
            .call_order_fio_input {
              height: 30px;
              //margin-left: 5px;
              padding-left: 8px;
              font-size: 20px;
              //width: 150px;
              margin-top: 16px;
              @media (max-width: 1000px) {
                width: 220px;
              }
            }
            .call_order_number_input {
              height: 30px;
              margin-left: 5px;
              padding-left: 8px;
              font-size: 20px;
              //width: 150px;
              margin-top: 20px;
              @media (max-width: 1000px) {
                width: 220px;
              }
            }
            .call_order_number_input::-webkit-outer-spin-button,
            .call_order_number_input::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
          }
          .call_order_info_confirm {
            display: flex;
            flex-direction: row;
            padding: 20px;
            font-family: nunito;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
          .call_order_info_btn {
            background-color: rgb(219, 41, 41);
            color: white;

            font-size: 20px;
            width: 200px;
            height: 50px;
            margin: auto;
            border: double white 5px;
            box-shadow: 0px 3px 6px lightgrey;
            border-radius: 10px;
            cursor: pointer;
            margin-top: 40px;
          }
        }
      }
      .call_order_panel_hide {
        display: none;
      }
    }
    //////////////////////////////////////
    .call_order_div_min {
      // background-color: aqua;
      position: absolute;
      display: flex;
      right: 60px;
      top: -150px;
      width: 100px;
      height: 100px;
      overflow: hidden;
      // background-color: forestgreen;
      // background-color: aqua;
      @media (max-width: 1000px) {
        right: -12px;
        top: -80px;
      }
      .call_order_btn_show {
        background-color: rgb(219, 41, 41);
        width: 80px;
        height: 80px;
        border-radius: 100px;
        border: 8px double white;
        margin: auto;
        display: flex;

        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (max-width: 1000px) {
          width: 50px;
          height: 50px;
          margin-top: 10px;
          margin-left: 10px;
        }
      }

      .call_order_panel_hide {
        display: none;
      }
    }
  }
}
