.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  //max-width: 1850px;
  margin: auto;

  margin-top: 15px;
  margin-bottom: 80px;

  justify-content: space-evenly;
  @media (max-width: 1570px) {
  }
  @media (max-width: 1375px) {
  }
  @media (max-width: 1000px) {
    margin-top: 50px;
    flex-direction: column;
    max-width: 450px;
  }

  .info1 {
    width: 40%;
    @media (max-width: 1570px) {
      width: 40%;
    }
    @media (max-width: 1000px) {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 1000px) {
      width: 90%;
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
    }

    div:first-child {
      font-size: 26px;
      font-weight: 200;
      font-family: nunito;
      border-top: thin solid rgb(189, 189, 189);
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      // box-shadow: 0px 5px 6px -3px lightgrey;
      margin-bottom: 15px;
      margin-left: 10px;
      @media (max-width: 1570px) {
        font-size: 24px;
      }
      @media (max-width: 1375px) {
        font-size: 20px;
      }
    }
    div:last-child {
      font-family: nunito;
      font-size: 18px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: justify;
      @media (max-width: 1570px) {
        font-size: 16px;
      }
      @media (max-width: 1375px) {
      }
      @media (max-width: 1000px) {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 14px;
      }
    }
    @media (max-width: 1375px) {
    }
  }
  .info2 {
    width: 40%;
    @media (max-width: 1570px) {
      width: 40%;
      @media (max-width: 1000px) {
        width: 90%;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    div:first-child {
      font-size: 26px;
      font-weight: 200;
      font-family: nunito;
      border-top: thin solid rgb(189, 189, 189);
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 15px;
      margin-left: 10px;
      @media (max-width: 1570px) {
        font-size: 24px;
      }
      @media (max-width: 1375px) {
        font-size: 20px;
      }
      @media (max-width: 1000px) {
      }
    }
    div:last-child {
      ul {
        li {
          font-family: nunito;

          text-align: justify;
          font-size: 18px;
          padding-left: 20px;
          padding-right: 20px;
          @media (max-width: 1570px) {
            font-size: 16px;
          }
          @media (max-width: 1375px) {
          }
          @media (max-width: 1000px) {
            font-size: 14px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
