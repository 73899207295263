.commonCCP {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.CatCatPanel_div {
  position: relative;
  margin-top: -30px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 200px;
  //border-bottom: thin solid grey;
  @media (max-width: 1375px) {
    margin-top: -30px;
    width: 100%;
    height: 180px;
  }
  @media (max-width: 1000px) {
    margin-top: -40px;
    height: 200px;
  }

  .ccp_cat_div {
    display: flex;
    flex-direction: column;
    width: 50%;
    //  min-width: 600px;
    height: 100%;
    @media (max-width: 2200px) {
      width: 40%;
      //min-width: 600px;
    }
    @media (max-width: 1550px) {
      width: 40%;
      //min-width: 500px;
    }
    @media (max-width: 1140px) {
    }

    @media (max-width: 1000px) {
      width: 100%;
      //   min-width: 100px;
    }

    .ccp_title {
      // width: 95%;
      height: 50%;
      // min-width: 600px;
      // background-color: blue;
      display: flex;
      div:first-child {
        display: flex;
        margin-top: 38px;
        background-color: rgb(219, 41, 41);
        z-index: -2;
        justify-content: end;
        width: 90%;
        @media (max-width: 3600px) {
          width: 80%;
          height: 60px;
        }

        @media (max-width: 1375px) {
          width: 85%;
          height: 50px;
        }

        @media (max-width: 1000px) {
          width: 50%;
          height: 40px;
        }
        p {
          font-size: 35px;
          color: white;
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 90px;
          font-family: 'Jura';
          font-weight: 500;
          @media (max-width: 1375px) {
            position: absolute;
            margin-top: 5px;
            font-size: 30px;
          }
          @media (max-width: 1000px) {
            position: absolute;
            margin: auto;
            margin-right: 35px;
            margin-top: 5.5px;
            font-size: 25px;
          }
        }
      }
      div:last-child {
        position: relative;
        margin-top: 47.5px;
        margin-left: -21px;
        background-color: white;
        border: thin solid rgb(219, 41, 41);
        width: 40px;
        height: 40px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: -1;
        @media (max-width: 1375px) {
          width: 34px;
          height: 34px;
          margin-top: 45px;
          margin-left: -18px;
        }

        @media (max-width: 1000px) {
          width: 26px;
          height: 26px;
          margin-top: 45px;
          margin-left: -14px;
        }
      }
    }
    .ccp_chose {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      //background-color: salmon;
      width: 100%;

      height: 50%;
      @media (max-width: 1375px) {
        justify-content: center;
      }
      @media (max-width: 1140px) {
        flex-direction: column;
        margin-top: -20px;
      }
      @media (max-width: 1000px) {
        flex-direction: column;
        margin-top: -20px;
      }
      p {
        font-family: 'nunito';
        font-size: 28px;
        margin-right: 1%;
        z-index: -1;
        @media (max-width: 1375px) {
          font-size: 24px;
        }
        @media (max-width: 1140px) {
          flex-direction: column;
        }
        @media (max-width: 1000px) {
          font-size: 24px;
          margin-top: 0px;
          margin-right: 20px;
        }
      }
      div {
        display: flex;
        width: fit-content;
        max-width: 270px;
        min-width: 70px;
        min-height: 50px;
        padding-left: 1%;
        padding-right: 1%;
        padding-bottom: 3px;
        height: fit-content;
        background-color: rgb(167, 167, 167);
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        border-radius: 15px;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
        @media (max-width: 1375px) {
          font-size: 22px;
        }
        @media (max-width: 1140px) {
          margin-top: -25px;
        }
        @media (max-width: 1000px) {
          font-size: 20px;
          padding: 4px;
          padding-bottom: 7px;
          min-height: 30px;
          height: 40px;
          margin-top: -14px;
        }
      }
      // background-color: aqua;
    }
  }
  .ccp_info_div {
    position: relative;
    width: 60%;
    min-width: 1000px;
    display: flex;
    // background-color: antiquewhite;
    z-index: -3;
    @media (max-width: 2200px) {
      width: 70%;
      min-width: 600px;
    }
    @media (max-width: 1550px) {
      width: 70%;
      min-width: 600px;
    }
    @media (max-width: 1090px) {
      width: 70%;
      min-width: 550px;
    }
    @media (max-width: 1000px) {
      display: none;
    }
    div:first-child {
      display: flex;
      justify-content: start;
      align-items: center;
      margin: auto;
      width: 100%;
      margin-left: 0;
      margin-top: 44px;
      height: 130px;
      background-color: rgb(219, 41, 41);
      @media (max-width: 1375px) {
        height: 120px;
      }

      @media (max-width: 1000px) {
        display: none;
      }
      p {
        text-align: center;
        font-size: 42px;
        margin-left: 230px;

        font-family: jura;
        color: white;
        @media (max-width: 2200px) {
          min-width: 100px;
          font-size: 39px;
          margin-left: 170px;
        }
        @media (max-width: 2100px) {
          min-width: 100px;
          font-size: 36px;
          margin-left: 240px;
        }
        @media (max-width: 1700px) {
          font-size: 38px;
          margin-left: 160px;
        }

        @media (max-width: 1550px) {
          text-align: center;
          font-size: 36px;

          margin-left: 170px;
        }
        @media (max-width: 1375px) {
          font-size: 30px;
          margin-left: 120px;
        }
        @media (max-width: 1055px) {
          font-size: 26px;
          margin-left: 100px;
        }
      }
    }
    div:last-child {
      position: absolute;
      // border: thin solid grey;
      top: -100px;
      left: -110px;
      margin: auto;
      width: 200px;
      height: 350px;
      background-color: white;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      @media (max-width: 1375px) {
        left: -150px;
      }
    }
  }
  //////////////////////////////////////////
  ///
}
.show_hide_CCP {
  position: absolute;
  top: 165px;
  width: 98%;
  max-width: 1500px;
  z-index: 2;
  margin: auto;

  height: fit-content;
  border: thin solid grey;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 2px 5px grey;
  left: -20px;
  right: 0px;
  @media (max-width: 1785px) {
    top: 150px;
    left: 0px;
  }
  @media (max-width: 1375px) {
    top: 150px;
    left: 0px;
  }
  @media (max-width: 1000px) {
    top: 150px;
    left: 0px;
  }

  .category_item {
    display: flex;
    width: fit-content;
    min-width: 10px;
    padding-left: 2%;
    padding-right: 2%;
    height: 60px;
    margin: 2%;
    background-color: white;
    border: thin solid gray;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgb(96, 96, 96);
    border-radius: 15px;
    font-size: 20px;
    font-weight: 300;
    box-shadow: 0px 2px 5px grey;
    cursor: pointer;
    @media (max-width: 1375px) {
      font-size: 20px;
    }
    @media (max-width: 1000px) {
      font-size: 20px;
      height: 40px;
    }
  }
  .current_category_item {
    display: flex;
    width: fit-content;
    min-width: 10px;
    padding-left: 2%;
    padding-right: 2%;
    height: 60px;
    margin: 2%;
    background-color: (167, 167, 167);
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    border-radius: 15px;
    background-color: rgb(167, 167, 167);
    font-size: 20px;
    font-weight: 300;

    cursor: pointer;
    @media (max-width: 1375px) {
      font-size: 20px;
    }
    @media (max-width: 1000px) {
      font-size: 20px;
      height: 40px;
    }
  }
}
//1752
