.root {
  position: fixed;
  display: flex;

  width: 100%;
  //  max-width: 3200px;
  // height: 90px;
  top: 0%;
  z-index: 100;
  background-color: white;

  justify-content: center;
  border-bottom: thin solid lightgrey;
  box-shadow: 0px 2px 10px grey;
  @media (max-width: 1375px) {
    height: 85px;
  }
  @media (max-width: 1000px) {
    justify-content: space-around;
    height: 85px;
  }
  .root2 {
    display: flex;
    //background-color: aqua;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    //  max-width: 3200;
    @media (max-width: 1800px) {
      height: 85px;
      width: 100%;
    }
    @media (max-width: 1375px) {
      height: 85px;
      width: 100%;
    }
    @media (max-width: 1000px) {
      justify-content: space-around;
      height: 85px;
    }
    @media (max-width: 500px) {
    }
    ///////////////////////////////////////////////////////////////////
    .show_hide_menu_btn_div {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 80px;
      min-width: 80px;
      justify-content: center;
      height: 50px;

      div {
        width: 100px;
        padding: 5px;
        border-radius: 20px;
        border: thin solid grey;
        background-color: rgb(219, 41, 41);
        box-shadow: 0px 3px 6px grey;
      }
      @media (min-width: 1001px) {
        display: none;
      }
      @media (max-width: 500px) {
        height: 40px;
        width: 70px;
        min-width: 70px;
      }
    }

    ////////////////////////////////////////////////////////////////////
    .full_logo_div {
      // margin-left: 40px;
      position: relative;
      display: flex;
      width: 260px;
      min-width: 260px;

      @media (max-width: 1375px) {
        width: 170px;
        min-width: 170px;
      }

      .r_logo_wall {
        position: absolute;
        height: 90px;
        margin-left: 5.2px;
        z-index: 0;
        -moz-transform: scale(-1, -1);
        -o-transform: scale(-1, -1);
        -webkit-transform: scale(-1, -1);
        transform: scale(-1, -1);
        @media (max-width: 1375px) {
          height: 85px;
          margin-left: -12px;
        }
      }
      img {
        width: 90px;
        margin-left: 66px;
        z-index: 10;
        border: medium solid rgb(189, 174, 174);
        @media (max-width: 1375px) {
          width: 85px;
          margin-left: 44px;
        }
      }
      .l_logo_wall {
        position: absolute;

        height: 90px;
        margin-left: 131.4px;
        @media (max-width: 1375px) {
          height: 85px;
          margin-left: 105px;
        }
      }
    }
    /////////////////////////////////////////////////////////////////
    @keyframes slideShow {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
        z-index: 100;
      }
    }
    @keyframes slideHide {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
        z-index: 100;
      }
    }
    .show_header_panel {
      @media (max-width: 1375px) {
        position: absolute;
        display: flex;
        top: 85.5px;
        width: 100%;
        border: thin solid lightgrey;
        background-color: white;
        z-index: 100;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 700px;
        box-shadow: 0px 3px 10px grey;
        // transform: translate(100px, 50px);
        animation: slideShow 0.3s linear;
      }
    }
    .hide_header_panel {
      @media (max-width: 1375px) {
        position: absolute;
        display: flex;
        top: 85.5px;
        width: 100%;
        border: thin solid lightgrey;
        background-color: white;
        z-index: 100;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 700px;
        transform: translate(-100%, 0px);
        box-shadow: 0px 3px 10px grey;
        animation: slideHide 0.3s linear;
      }
    }
    .show_hide_header_panel {
      position: relative;
      display: flex;
      flex-direction: row;
      // margin-left: -20px;
      // background-color: cadetblue;
      justify-content: space-evenly;
      width: 55%;
      z-index: 1000;

      min-width: 1110px;
      @media (max-width: 1700px) {
        min-width: 1000px;
      }
      @media (max-width: 1510px) {
        min-width: 880px;
      }
      @media (max-width: 1375px) {
        min-width: 777px;
        ///  justify-content: start;
      }
      @media (max-width: 1111px) {
        min-width: 677px;
      }
      @media (max-width: 1000px) {
        position: absolute;
        top: 122px;
        width: 100%;
        border: thin solid lightgrey;
        background-color: white;
        z-index: 10000;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 100px;
        transform: translate(-100%, 0);
        // transform: translateX(-100%);
      }
    }
    .search_input_div {
      position: relative;
      margin-top: auto;
      margin-bottom: auto;
      width: 520px;
      min-width: 520px;
      @media (max-width: 1700px) {
        width: 400px;
        min-width: 400px;
        //  background-color: antiquewhite;
      }
      @media (max-width: 1111px) {
        min-width: 320px;
        //  background-color: antiquewhite;
      }
      //  background-color: red;
      .search_wall_l {
        display: flex;
        width: 90px;
        position: absolute;
        top: -20.5px;
        left: -72px;
        @media (max-width: 1510px) {
          display: none;
        }
      }
      .disableLink {
        pointer-events: none;
      }
      .search_icon {
        left: 10px;
        top: 10px;
        position: absolute;
        width: 30px;
        @media (max-width: 1510px) {
          left: 30px;
        }
        @media (max-width: 1375px) {
          left: 35px;
          top: 9px;
          width: 30px;
        }
        @media (max-width: 1000px) {
          left: 60px;
          top: 10px;
        }
      }
      .search_enter_arrow {
        position: absolute;
        width: 70px;
        top: -10px;
        right: 153px;
        fill: lightgrey;
        z-index: -0;
        @media (max-width: 1700px) {
          right: 94px;
        }
        @media (max-width: 1510px) {
          right: 72px;
        }
        @media (max-width: 1375px) {
          width: 68px;
          top: -10.5px;
          right: 87px;
        }
        @media (max-width: 1111px) {
          width: 70px;
          top: -13px;
          right: 5px;
        }
        @media (max-width: 1000px) {
          width: 80px;
          top: -12.5px;
          right: 40px;
        }
      }

      .search_enter_arrow_active {
        position: absolute;
        width: 70px;
        top: -10px;
        right: 153px;
        fill: rgb(92, 228, 92);
        z-index: 2;
        @media (max-width: 1700px) {
          right: 94px;
        }
        @media (max-width: 1510px) {
          right: 72px;
        }
        @media (max-width: 1375px) {
          width: 68px;
          top: -12px;
          right: 87px;
        }
        @media (max-width: 1111px) {
          width: 70px;
          top: -13px;
          right: 5px;
        }
        @media (max-width: 1000px) {
          width: 80px;
          top: -13px;
          right: 40px;
        }
      }
      .search_wall_r {
        width: 90px;
        position: absolute;
        top: -20.2px;
        left: 360px;
        @media (max-width: 1700px) {
          left: 294px;
        }
        @media (max-width: 1510px) {
          display: none;
        }
      }
      input {
        //margin-left: 60px;
        width: 280px;
        height: 45px;
        //  min-height: 45px;
        border: thin solid grey;
        border-radius: 5px;
        font-size: 18px;
        padding-left: 70px;
        box-shadow: 0px 3px 6px lightgrey;

        @media (max-width: 1700px) {
          width: 220px;
        }
        @media (max-width: 1510px) {
          margin-left: 20px;
        }
        @media (max-width: 1375px) {
          width: 200px;
          height: 40px;
          min-height: 40px;
          margin-left: 25px;
        }
        @media (max-width: 1000px) {
          margin-bottom: 10px;
          margin-left: 50px;
          width: 220px;
          height: 50px;
          min-height: 50px;
        }
      }
    }

    ul {
      //  background-color: azure;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // background-color: aquamarine;
      @media (max-width: 1111px) {
        min-width: 340px;
      }
      @media (max-width: 1000px) {
        flex-direction: column;
        margin-top: 40px;
      }
      // background-color: antiquewhite;
      li {
        text-decoration: none;
        list-style-type: none;
        padding: 4px;
        color: rgb(61, 61, 61);
        font-family: 'Nunito';
        font-size: 23px;
        @media (max-width: 1700px) {
          font-size: 23px;
        }
        @media (max-width: 1375px) {
          padding: 3px;
          font-size: 20px;
        }
        @media (max-width: 1000px) {
          margin-left: -41px;
          font-size: 28px;
        }
      }
      .palka {
        font-weight: 100;
        color: rgb(120, 120, 120);
        @media (max-width: 1375px) {
          visibility: hidden;
        }
      }
    }
    ////////////////////////////////////////////////////////////////////
    .cart_data_div {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100px;
      min-width: 100px;
      border-radius: 20px;
      height: 70px;
      border: thin solid grey;
      background-color: rgb(219, 41, 41);
      box-shadow: 0px 3px 6px grey;
      @media (max-width: 1375px) {
        width: 86px;
        min-width: 86px;
        height: 70px;
        flex-direction: column;
      }
      @media (max-width: 500px) {
        height: 60px;
        width: 80px;
        min-width: 80px;
      }
      div {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1375px) {
          height: 50%;
          width: 100%;
        }
        p {
          text-decoration: none;
          color: white;
          font-size: 16px;
          font-weight: bold;
          @media (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
      div:first-child {
        position: relative;
        border-bottom: thin solid white;
        padding-right: 5px;
        margin-left: 0px;
        @media (max-width: 1375px) {
          margin-left: 0px;
          border-right: none;
          //border-top: solid thin white;
          border-bottom: solid thin white;
        }
        svg {
          margin-left: -36px;
        }
        p {
          text-decoration: none;
          position: absolute;
          bottom: -13px;
          right: 25px;
          @media (max-width: 1375px) {
            right: 21px;
          }
        }
      }
    }

    /////////////////////////////////////////
    .wall_r {
      width: 90px;
      min-width: 90px;
      //margin-right: -90px;
      @media (max-width: 1250px) {
        display: none;
      }
    }
  }
}
