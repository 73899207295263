.root {
  width: 100%;
  max-width: 1800px;
  padding-bottom: 40px;
  margin: auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Edge, Opera and Firefox */
  .title_LI {
    position: relative;
    width: 100%;
    height: 50%;
    margin-bottom: 20px;
    min-width: 100px;
    // background-color: blue;
    display: flex;
    flex-direction: row;
    div:last-child {
      display: flex;
      margin-top: 38px;
      background-color: rgb(153, 153, 153);
      width: 100%;
      border-radius: 10px;
      margin-left: -10px;
      height: 50px;
      z-index: 0;
      justify-content: end;

      @media (max-width: 1375px) {
        width: 100%;

        height: 50px;
      }
      @media (max-width: 1000px) {
        width: 100%;
        height: 40px;
      }
      @media (max-width: 500px) {
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 98%;
        margin: auto;
        margin-top: 38px;
        // background-color: aliceblue;
      }
      p {
        font-size: 38px;
        color: white;

        margin: auto;
        margin-top: -2px;
        font-family: 'Jura';
        font-weight: 500;

        @media (max-width: 1375px) {
          margin: auto;
          font-size: 40px;
          margin-top: -2px;
        }
        @media (max-width: 1000px) {
          margin-left: 40px;

          margin-top: 2px;
          font-size: 27px;
        }
        @media (max-width: 500px) {
          margin: auto;
          margin-top: 0px;
        }
      }
      .all_items_btn {
        position: absolute;
        font-family: 'jura';
        font-weight: 900;
        top: 5.3px;
        right: 20px;
        width: 190px;
        height: 40px;
        color: white;
        border-radius: 12px;
        font-size: 20px;
        justify-content: center;
        align-items: center;

        text-align: center;
        background-color: rgb(219, 41, 41);
        cursor: pointer;
        @media (max-width: 1375px) {
          height: 42px;
          top: 4px;
          font-size: 22px;
          width: 180px;
          right: 15px;
        }
        @media (max-width: 1000px) {
          font-size: 14px;
          height: 33px;
          width: 110px;
          right: 14px;
        }
        @media (max-width: 500px) {
          top: 355px;
        }
      }
    }
    div:first-child {
      position: absolute;
      margin-top: 38px;
      margin-left: -30px;
      background-color: white;
      //   border: thin solid grey;
      width: 50px;
      height: 50px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: 2;
      @media (max-width: 1375px) {
        width: 34px;
        height: 34px;
        margin-top: 45px;
        margin-left: -18px;
      }
      @media (max-width: 1000px) {
        width: 26px;
        height: 26px;
        margin-top: 45px;
        margin-left: -14px;
      }
      @media (max-width: 500px) {
        display: none;
      }
    }
  }

  .items_line_group {
    width: 90%;
    margin-left: 5%;

    // border-left: thin solid lightgrey;
    //border-right: thin solid lightgrey;
    display: flex;
    flex-direction: row;
    //margin-left: 50px;
    overflow-x: auto;
    @media (max-width: 1800px) {
      width: 88%;
      margin-left: 6%;
    }
    @media (max-width: 1500px) {
      width: 86%;
      margin-left: 7%;
    }
    @media (max-width: 1250px) {
      width: 84%;
      margin-left: 8%;
    }
    @media (max-width: 1150px) {
      width: 82%;
      margin-left: 9%;
    }
    @media (max-width: 832px) {
      width: 100%;
      margin-left: 0%;
    }
  }
  .arrow_left {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: lightgray;
    z-index: 100px;
    top: 270px;
    border-radius: 10px;
    left: 20px;
    fill: grey;
    @media (max-width: 1150px) {
      width: 45px;
      height: 45px;
    }
    @media (max-width: 832px) {
      top: 390px;
      width: 40px;
      height: 40px;
      // left: 32%;
    }
  }
  .arrow_right {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: lightgray;
    z-index: 100px;
    top: 270px;
    right: 20px;
    border-radius: 10px;
    fill: grey;
    @media (max-width: 1150px) {
      width: 45px;
      height: 45px;
      //margin-left: 9%;
    }
    @media (max-width: 832px) {
      top: 390px;
      width: 40px;
      height: 40px;
      //   right: 32%;
    }
    @media (max-width: 500px) {
      top: 390px;
      right: 340px;
      left: 80px;
      width: 40px;
      height: 40px;
      //   right: 32%;
    }
  }
}
