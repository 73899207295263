.root {
  display: flex;
  flex-direction: column;
  width: 50%;
  // background-color: antiquewhite;
  height: 450px;
  margin: auto;
  margin-left: 2.5%;
  //  margin-bottom: 0px;
  justify-content: center;
  z-index: 2;
  @media (max-width: 1570px) {
    width: 45%;
    margin-left: 5%;
    height: 400px;
    justify-content: center;
  }
  @media (max-width: 1375px) {
    height: 300px;
    margin-left: 60px;
    //  background-color: antiquewhite;
    justify-content: center;
    margin-top: 110px;
    //width: 550px;
  }
  @media (max-width: 1151px) {
    height: 300px;
    margin-left: 80px;
    // background-color: antiquewhite;
    justify-content: center;
    margin-top: 110px;
    //width: 550px;
  }

  @media (max-width: 1000px) {
    height: fit-content;
    margin-left: 0px;
    margin: auto;
    //  background-color: antiquewhite;
    justify-content: start;
    margin-top: 20px;
    width: 100%;
    max-width: 450px;
  }

  .item_variants {
    width: 100%;
    height: 290px;

    @media (max-width: 1570px) {
      width: 100%;
      height: 240px;
    }
    @media (max-width: 1375px) {
      height: 210px;
    }
    @media (max-width: 1000px) {
      height: 190px;
    }

    div:first-child {
      display: flex;
      width: 100%;
      height: 100px;
      @media (max-width: 1570px) {
        width: 100%;
        height: 80px;
      }
      @media (max-width: 1375px) {
        height: 60px;
      }
      @media (max-width: 1000px) {
        height: 50px;
      }
      // border: thin solid grey;
      p {
        //background-color: antiquewhite;
        margin: auto;
        margin-bottom: 10px;
        font-family: nunito;
        font-size: 34px;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: thin solid rgb(179, 179, 179);
        box-shadow: 0px 5px 4px -5px rgb(164, 164, 164);
        @media (max-width: 1570px) {
          font-size: 28px;
        }
        @media (max-width: 1375px) {
          font-size: 25px;
        }
        @media (max-width: 1000px) {
          font-size: 22px;
          padding-top: 20px;
        }
      }
    }
    div:last-child {
      width: 100%;
      // background-color: gold;
      margin: auto;
      // padding-bottom: 20px;
      height: 190px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      @media (max-width: 1570px) {
        height: 170px;
        width: 100%;
      }
      @media (max-width: 1375px) {
        height: 140px;
      }
      @media (max-width: 1000px) {
        overflow: auto;

        //background-color: aqua;
        //justify-content: space-between;
        height: 100px;
        width: 90%;
        margin-top: 20px;
        // background-color: antiquewhite;
      }
      .variant_on {
        text-align: center;
        border: thin solid grey;
        border-radius: 10px;
        background-color: rgb(162, 162, 162);
        color: white;
        padding: 5px;
        min-width: 120px;
        margin: auto;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 28px;
        cursor: pointer;
        @media (max-width: 1570px) {
          min-width: 100px;
          font-size: 20px;
          margin-left: 5px;
          margin-right: 5px;
        }
        @media (max-width: 1375px) {
          min-width: 100px;
          font-size: 18px;
          margin-left: 3px;
          margin-right: 3px;
          padding-left: 0px;
          padding-right: 0px;
        }
        @media (max-width: 1000px) {
          font-size: 16px;
          margin-left: 10px;
          min-width: 70px;
        }
      }
      .variant_off {
        text-align: center;
        border: thin solid grey;
        border-radius: 10px;
        background-color: white;
        color: rgb(61, 61, 61);
        padding: 5px;
        min-width: 120px;
        margin: auto;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 28px;
        cursor: pointer;
        @media (max-width: 1570px) {
          min-width: 100px;
          font-size: 20px;
          margin-left: 5px;
          margin-right: 5px;
        }
        @media (max-width: 1375px) {
          min-width: 100px;
          font-size: 18px;
          margin-left: 3px;
          margin-right: 3px;
          padding-left: 0px;
          padding-right: 0px;
        }
        @media (max-width: 1000px) {
          font-size: 16px;
          margin-left: 10px;
          min-width: 70px;
        }
      }
    }
  }
  .price_div {
    position: relative;
    //background-color: aqua;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: -2.2%;
    margin-top: 10px;
    height: 100px;
    @media (max-width: 1570px) {
      min-width: 100px;
      height: 90px;
    }
    @media (max-width: 1375px) {
      height: 80px;
      margin-left: -2.5%;
    }
    @media (max-width: 1000px) {
      flex-wrap: wrap;
      height: fit-content;

      //  background-color: antiquewhite;
      width: 90%;
      margin: auto;
      margin-top: 0px;
    }

    .price {
      width: 35%;
      max-width: 35%;
      height: 100%;

      //background-color: antiquewhite;
      display: flex;
      margin-top: auto;
      margin-bottom: auto;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-family: nunito;
      @media (max-width: 1570px) {
        font-size: 21px;
        width: 35%;
      }
      @media (max-width: 1375px) {
        font-size: 20px;
      }
      @media (max-width: 1000px) {
        width: 100%;
        min-width: 100%;
        padding-bottom: 20px;
      }
    }
    .count_div {
      margin-left: -20%;
      margin: auto;
      width: 30%;
      max-width: 200px;
      height: 50px;
      border: thin solid grey;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
      box-shadow: 0px 3px 7px gray;
      @media (max-width: 1570px) {
        height: 40px;
        max-width: 140px;
        width: 180px;
      }
      @media (max-width: 1375px) {
        height: 40px;
        width: 150px;
      }
      @media (max-width: 1000px) {
        width: 40%;
      }
      .plus {
        text-align: center;
        width: 30%;
        font-size: 30px;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
        @media (max-width: 1570px) {
          font-size: 24px;
        }
        @media (max-width: 1375px) {
          font-size: 20px;
        }
      }
      .count::-webkit-outer-spin-button,
      .count::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;

        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }
      .count:focus {
        border: 0px;
        outline: none;
      }
      .count {
        border-left: thin solid grey;
        border-right: thin solid grey;
        text-align: center;
        width: 50%;
        font-size: 26px;
        border: none;
        @media (max-width: 1570px) {
          font-size: 24px;
        }
        @media (max-width: 1375px) {
          font-size: 20px;
        }
      }
      .minus {
        text-align: center;
        width: 30%;
        font-size: 30px;
        cursor: pointer;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
        @media (max-width: 1570px) {
          font-size: 24px;
        }
        @media (max-width: 1375px) {
          font-size: 20px;
        }
      }
    }
    .to_cart_btn {
      margin: auto;
      width: 15%;
      max-width: 15%;

      height: 50px;
      color: white;
      border-radius: 100px;
      background-color: rgb(219, 41, 41);

      display: flex;
      margin: auto;
      text-align: center;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-family: nunito;
      padding-left: 8px;
      padding-right: 8px;
      border: thin solid grey;
      cursor: pointer;
      @media (max-width: 1570px) {
        font-size: 19px;
        height: 55%;
        width: 25%;
      }
      @media (max-width: 1375px) {
        font-size: 15px;
        height: 50%;
      }
      @media (max-width: 1000px) {
        width: 32%;
        min-width: 32%;
        height: 40px;
        //margin-top: 10px;
      }
    }
  }
}
