.root {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 1900px;
  //background-color: antiquewhite;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -40px;
  @media (max-width: 1375px) {
    margin-top: -50px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-top: -80px;
  }
  .ipp_title {
    position: absolute;
    width: 97.5%;

    height: 50%;
    min-width: 600px;
    // background-color: blue;
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: 1000px) {
      min-width: 0px;
      width: 100%;
    }
    .back_red {
      position: absolute;
      display: flex;
      margin-top: 58px;
      left: 1000px;
      padding-left: 11111px;
      background-color: rgb(219, 41, 41);
      width: 54%;
      height: 60px;
      z-index: -4;

      @media (max-width: 2100px) {
        width: 54%;
        height: 60px;
      }
      @media (max-width: 1960px) {
        width: 54%;
        height: 70px;
      }
      @media (max-width: 1570px) {
        width: 55%;
        height: 60px;
      }
      @media (max-width: 1151px) {
        left: 580px;
      }
    }
    div:first-child {
      display: flex;
      margin-top: 58px;
      background-color: rgb(219, 41, 41);
      width: 54%;
      height: 60px;
      z-index: -2;
      justify-content: center;
      align-items: center;
      // text-align: center;
      @media (max-width: 2100px) {
        width: 54%;
        height: 60px;
      }
      @media (max-width: 1960px) {
        width: 54%;
        height: 70px;
      }
      @media (max-width: 1570px) {
        width: 55%;
        justify-content: center;
        align-items: center;
        height: 60px;
      }
      @media (max-width: 1151px) {
        width: 52%;
      }
      @media (max-width: 1000px) {
        width: 40%;
        height: 110px;
        width: 100%;
        margin-bottom: 56px;
      }
      p {
        position: absolute;
        //  background-color: aliceblue;
        color: white;
        font-family: 'Jura';
        font-weight: 500;
        margin: auto;
        text-align: center;
        font-size: 26px;
        margin-top: -6px;
        padding-left: 45px;
        padding-right: 45px;

        @media (max-width: 1570px) {
          position: absolute;
          text-align: center;
          margin-top: -3px;
          font-size: 24px;
          padding-left: 35px;
          padding-right: 35px;
        }
        @media (max-width: 1200px) {
          position: absolute;
          margin: auto;
          text-align: center;
          margin-top: -2px;
          font-size: 23px;
          padding-left: 35px;
          padding-right: 35px;
        }
        @media (max-width: 1000px) {
          position: absolute;
          margin: auto;
          text-align: center;
          margin-top: 20px;
          font-size: 20px;
          font-weight: 500;
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
    div:last-child {
      position: relative;
      margin-top: 66.88px;
      margin-right: -21.5px;
      background-color: white;
      border: thin solid rgb(219, 41, 41);
      width: 40.4px;
      height: 40.4px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: -1;
      @media (max-width: 1960px) {
        width: 48px;
        height: 48px;
        margin-right: -26px;
      }
      @media (max-width: 1570px) {
        width: 40.5px;
        height: 40.5px;
        margin-top: 66.5px;
        margin-right: -21px;
      }
      @media (max-width: 1000px) {
        width: 26px;
        height: 26px;
        margin-top: 45px;
        margin-left: -14px;
        display: none;
      }
    }
  }
}
