.root {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 1050px;
  margin: auto;
  margin: 3px;
  height: 100px;
  border-bottom: thin solid rgb(174, 174, 174);
  box-shadow: 0px 2px 1px rgb(235, 235, 235);
  @media (max-width: 1425px) {
    width: 990px;
    height: 100px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    margin: 25px;
    max-width: 600px;
  }
  //1425
  .img {
    display: flex;
    width: 200px;
    height: 100%;
    border-right: thin solid rgb(155, 154, 154);
    @media (max-width: 1000px) {
      height: 100px;
      width: 35%;
      // min-width: 110px;
      border: none;
    }
    img {
      margin: auto;
      height: 70%;
    }
  }
  .item_name_div_noVar {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 100%;
    border-right: thin solid rgb(155, 154, 154);
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 1425px) {
      width: 470px;
    }
    @media (max-width: 1000px) {
      position: absolute;

      top: -20px;
      left: 0px;
      width: 100%;
      height: 40px;
      border: none;
    }

    div:first-child {
      display: flex;
      font-size: 18px;
      font-family: nunito;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: 1425px) {
        font-size: 20px;
      }
      @media (max-width: 1000px) {
        font-size: 16px;
        // background-color: antiquewhite;
        width: 75%;
        margin-top: -15px;
        padding-left: 5%;
      }
    }
    div:last-child {
      display: none;
    }
  }
  .item_name_div {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 100%;
    border-right: thin solid rgb(155, 154, 154);
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 1425px) {
      width: 470px;
    }
    @media (max-width: 1000px) {
      position: absolute;

      top: -20px;
      left: 0px;
      width: 100%;
      height: 40px;
      border: none;
    }

    div:first-child {
      display: flex;
      font-size: 18px;
      font-family: nunito;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: 1425px) {
        font-size: 20px;
      }
      @media (max-width: 1000px) {
        font-size: 16px;
        // background-color: antiquewhite;
        width: 75%;
        margin-top: -15px;
        padding-left: 5%;
      }
    }
    div:last-child {
      display: flex;
      font-size: 18px;
      font-family: nunito;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: 1425px) {
        font-size: 20px;
      }
      @media (max-width: 1000px) {
        font-size: 16px;
        // background-color: antiquewhite;
        width: 75%;
        //  margin-top: -15px;
        padding-left: 5%;
      }
    }
  }
  .count_div {
    width: 300px;
    height: 100%;
    border-right: thin solid rgb(155, 154, 154);
    @media (max-width: 1425px) {
      width: 250px;
    }
    @media (max-width: 1000px) {
      height: 100px;
      width: 30%;
      border: none;
      padding-right: 3%;
    }
    div:first-child {
      display: flex;
      font-size: 20px;
      font-family: nunito;
      width: 100%;
      height: 50%;
      justify-content: center;
      align-items: center;

      @media (max-width: 1000px) {
        display: none;
        font-size: 16px;
      }
    }

    div:last-child {
      display: flex;

      width: 100%;
      height: 50%;
      justify-content: center;
      align-items: center;
      @media (max-width: 1000px) {
        height: 100%;
      }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 45%;
        margin-bottom: 5%;
        height: 70%;
        border-radius: 25px;
        border: thin solid grey;
        @media (max-width: 1425px) {
          width: 70%;
          height: 72%;
        }
        @media (max-width: 1000px) {
          height: 40%;
          width: 100%;
          margin-bottom: -2px;
        }

        .minus {
          border: none;
          border-right: thin solid grey;
          width: 30px;
          margin: auto;
          border-radius: 0px;
          font-size: 30px;
          font-weight: 300;
          min-width: 30px;
          cursor: pointer;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
          p {
            margin-left: 2px;
            margin-bottom: 32.5px;
            @media (max-width: 1425px) {
              margin-left: -2px;
            }
          }
        }
        p {
          text-align: center;
          width: 80px;
          max-width: 80px;
        }
        .plus {
          border: none;
          border-left: thin solid grey;
          width: 30px;
          font-size: 30px;
          font-weight: 300;
          margin: auto;
          border-radius: 0px;
          min-width: 30px;
          cursor: pointer;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
          p {
            margin-left: -2px;
            margin-bottom: 32.5px;
            @media (max-width: 1425px) {
              margin-left: 2px;
              width: 20%;
            }
          }
        }
      }
    }
  }
  .price {
    display: flex;
    flex-wrap: wrap;
    width: 180px;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: nunito;
    @media (max-width: 1425px) {
      font-size: 20px;
    }
    @media (max-width: 1000px) {
      height: 100px;
      width: 35%;
      text-align: center;
      font-size: 16px;
      border: none;
      padding-right: 5px;
    }
  }
  .delX {
    position: absolute;
    font-size: 20px;
    right: 0px;
    background-color: rgb(219, 41, 41);
    color: white;
    width: 23px;
    height: 23px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media (max-width: 1000px) {
      right: 5%;
      top: -27px;
    }
  }
}
