.root {
  width: 45%;
  height: 600px;
  margin-top: 19px;
  margin-left: -30px;
  justify-content: center;
  align-items: center;

  display: flex;
  @media (max-width: 1570px) {
    height: 500px;
    margin-top: 21px;
    width: 45%;
  }
  @media (max-width: 1375px) {
    height: 450px;
    margin-top: 9px;
    justify-content: center;
  }
  @media (max-width: 1150px) {
    height: 450px;
    justify-content: end;
    margin-left: -35px;
  }
  @media (max-width: 1000px) {
    display: flex;

    flex-direction: column-reverse;
    margin-left: 0px;

    margin: auto;
    margin-top: 150px;
  }
  .chose_img_div_hide {
    display: none;
  }
  .chose_img_div {
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: -6px;
    width: 15%;
    height: 80%;
    z-index: 2;
    @media (max-width: 1375px) {
      height: 75%;
      margin-top: 0px;
    }
    @media (max-width: 1000px) {
      flex-direction: row;
      // background-color: aqua;
      // justify-content: space-evenly;
      width: 100%;
      min-width: 310px;
      min-height: 20%;
      margin-top: 10px;
      display: flex;
      z-index: 5;
    }
    img {
      max-width: 70%;
      margin: auto;
      border-bottom: thin solid lightgrey;
      @media (max-width: 1000px) {
        max-height: 100%;
        height: 80%;
        padding-left: 5px;
        padding-right: 5px;
        border: none;
        border-right: thin solid lightgrey;
      }
    }
  }
  .show_img_div {
    display: flex;
    width: 65%;
    min-width: 500px;
    //max-width: 0px;
    height: 90%;
    min-height: 520px;
    max-height: 520px;
    border: thin solid lightgrey;
    border-radius: 5px;
    box-shadow: 0px 3px 6px grey;
    @media (max-width: 1570px) {
      min-height: 430px;
      height: 80%;
      width: 430px;
      min-width: 430px;
    }
    @media (max-width: 1375px) {
      min-height: 350px;
      height: 70%;
      width: 350px;
      min-width: 350px;
    }
    @media (max-width: 1111px) {
      // width: 320px;
      max-width: 100%;
      //   border: none;
      //box-shadow: none;
      // border-bottom: thin solid lightgray;
    }
    @media (max-width: 1000px) {
      // width: 320px;
      max-width: 100%;
      border: none;
      box-shadow: none;
      border-bottom: thin solid lightgray;
    }
    img {
      margin: auto;
      width: 75%;
    }
  }
}

.root2 {
  width: 45%;
  height: 600px;
  margin-top: 19px;
  margin-left: -30px;
  justify-content: center;
  align-items: center;

  display: flex;
  @media (max-width: 1570px) {
    height: 500px;
    margin-top: 21px;
    width: 45%;
  }
  @media (max-width: 1375px) {
    height: 450px;
    margin-top: 9px;
    justify-content: center;
  }
  @media (max-width: 1150px) {
    height: 450px;
    justify-content: end;
    margin-left: -35px;
  }
  @media (max-width: 1000px) {
    display: flex;

    flex-direction: column-reverse;
    margin-left: 0px;

    margin: auto;
    margin-top: 50px;
  }
  .chose_img_div_hide {
    display: none;
  }
  .chose_img_div {
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: -6px;
    width: 15%;
    height: 80%;
    z-index: 2;
    @media (max-width: 1375px) {
      height: 75%;
      margin-top: 0px;
    }
    @media (max-width: 1000px) {
      flex-direction: row;
      // background-color: aqua;
      // justify-content: space-evenly;
      width: 100%;
      min-width: 310px;
      min-height: 20%;
      margin-top: 10px;
      display: flex;
      z-index: 5;
    }
    img {
      max-width: 70%;
      margin: auto;
      border-bottom: thin solid lightgrey;
      @media (max-width: 1000px) {
        max-height: 100%;
        height: 80%;
        padding-left: 5px;
        padding-right: 5px;
        border: none;
        border-right: thin solid lightgrey;
      }
    }
  }
  .show_img_div {
    display: flex;
    width: 65%;
    min-width: 500px;
    //max-width: 0px;
    height: 90%;
    min-height: 520px;
    max-height: 520px;
    border: thin solid lightgrey;
    border-radius: 5px;
    box-shadow: 0px 3px 6px grey;
    @media (max-width: 1570px) {
      min-height: 430px;
      height: 80%;
      width: 430px;
      min-width: 430px;
    }
    @media (max-width: 1375px) {
      min-height: 350px;
      height: 70%;
      width: 350px;
      min-width: 350px;
    }
    @media (max-width: 1111px) {
      // width: 320px;
      max-width: 100%;
      border: none;
      box-shadow: none;
      border-bottom: thin solid lightgray;
    }
    img {
      margin: auto;
      width: 75%;
    }
  }
}
