.root {
  //border: thin solid grey;
  position: relative;
  display: grid;
  min-height: 100px;
  grid-template-columns: repeat(5, 1fr);
  // grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 3px;
  grid-row-gap: 3px;

  margin: auto;
  margin-bottom: 20px;
  max-width: 1479px;
  height: fit-content;
  z-index: 0;
  margin-top: 20px;
  // border: thin solid grey;
  @media (max-width: 1782px) {
    grid-template-columns: repeat(5, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    max-width: 1400px;
  }
  @media (max-width: 1462px) {
    grid-template-columns: repeat(5, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    max-width: 1456px;
  }
  @media (max-width: 1375px) {
    grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    max-width: 1100px;
  }
  @media (max-width: 1175px) {
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    max-width: 900px;
  }
  @media (max-width: 1055px) {
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    max-width: 830px;
  }
  @media (max-width: 1000px) {
    margin-top: 10px;
  }
  @media (max-width: 830px) {
    grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    max-width: 740px;
  }
  @media (max-width: 757px) {
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    max-width: 550px;
  }
  @media (max-width: 571px) {
    grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    max-width: 370px;
  }
  @media (max-width: 379px) {
    grid-template-columns: repeat(1, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    max-width: 170px;
  }

  .wrong_search {
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    border-bottom: thin solid grey;
    //background-color: antiquewhite;
    //  margin-left: -2%;
    font-size: 28px;
    font-family: nunito;
    @media (max-width: 1782px) {
      font-size: 25px;
    }
    @media (max-width: 1000px) {
      font-size: 17px;
    }
  }
}
