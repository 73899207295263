.root:hover {
  box-shadow: 0px 1px 10px grey;
  cursor: pointer;
  overflow: hidden;
}
.root {
  overflow: hidden;
  position: relative;
  width: 215px;
  min-width: 215px;
  height: 440px;
  border: thin solid lightgrey;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 15px;
  box-shadow: 0px 1px 10px lightgrey;
  padding-bottom: 20px;
  @media (max-width: 15375px) {
    width: 240px;
    min-width: 240px;
    height: 350px;
  }
  @media (max-width: 1055px) {
  }
  @media (max-width: 830px) {
    margin: 3.5px;
    width: 175px;
    min-width: 175px;
    height: 260px;
  }

  .img_part_div {
    position: relative;
    display: flex;
    margin: auto;
    width: 90%;
    //height: 68%;
    min-height: 68%;
    border-bottom: thin solid rgb(187, 187, 187);
    //background-color: antiquewhite;
    @media (max-width: 1000px) {
      //height: 60%;
      min-height: 65%;
    }
    img {
      z-index: -90;
      margin: auto;
      width: 100%;
      max-width: 330px;
      max-height: 220px;
    }
  }
  .info_part_div {
    margin: auto;
    width: 98%;
    height: 55%;
    display: flex;
    flex-direction: column;
    .item_name_div {
      display: flex;
      margin: auto;
      width: 100%;
      height: 72px;

      align-items: center;
      margin-top: 0%;
      justify-content: center;
      @media (max-width: 830px) {
        height: 80px;
      }
      // background-color: aqua;
      p {
        padding-left: 1%;
        padding-right: 1%;
        padding-top: 3%;
        text-decoration: none;
        font-family: 'nunito';
        font-weight: 400;
        font-size: 23px;
        text-align: center;
        color: rgb(66, 66, 66);
        @media (max-width: 15375px) {
          font-size: 16px;
        }
        @media (max-width: 1055px) {
        }
        @media (max-width: 830px) {
          font-size: 13.5px;
        }
      }
    }
    .item_price_div {
      display: flex;
      flex-direction: row;
      margin: auto;
      width: 100%;
      height: 40%;
      min-height: 55px;
      margin-top: 0%;
      padding-top: 1%;
      // background-color: antiquewhite;
      @media (max-width: 830px) {
        //  flex-direction: column;
        margin-top: -10px;
        min-height: 40px;
      }

      div:first-child {
        display: flex;
        justify-content: center;

        align-items: center;
        color: rgb(85, 85, 85);
        font-size: 22px;
        font-weight: bold;
        font-family: 'nunito';
        width: 48%;
        height: 100%;
        @media (max-width: 15375px) {
          font-size: 16px;
        }
        @media (max-width: 1055px) {
        }
        @media (max-width: 830px) {
          width: 100%;
          font-size: 14px;
        }
      }
      div:last-child {
        display: flex;
        width: 50%;
        height: 100%;
        @media (max-width: 830px) {
          width: 100%;
          height: 100%;
        }

        .add_to_cart_btn {
          display: flex;
          margin: auto;
          margin-left: -10px;
          width: 100%;
          height: 65%;
          background-color: rgb(219, 41, 41);
          border-radius: 205px;
          @media (max-width: 1375px) {
            width: 105%;
            height: 85;
          }

          @media (max-width: 830px) {
            //margin-top: 2px;
            // padding-top: 1px;
            padding-bottom: 1px;
            margin-right: 10%;
            height: 50%;
            width: 100%;
          }

          div:first-child {
            width: 100%;
            position: absolute;
            margin: auto;

            text-align: center; // margin-left: 29px;
            //background-color: aliceblue;
            color: white;
          }
          div:last-child {
            // width: 30px;
            //  height: 30px;
            //   background-color: white;
            //border-radius: 100px;
            //margin: auto;
            //         margin-right: 10px;
            //justify-content: center;
            //align-items: center;
            //font-size: 20px;
            //font-weight: 500;
            @media (max-width: 15375px) {
              //  width: 25px;
              // height: 25px;
              //font-size: 16px;
            }
            @media (max-width: 1055px) {
            }
            @media (max-width: 830px) {
              //width: 20px;
              //height: 20px;
            }
          }
        }
      }
    }
  }
  .link_to {
    position: absolute;
    margin: auto;
    width: 350px;
    height: 370px;
    min-height: 380px;
    z-index: 1;
    //background-color: antiquewhite;
    @media (max-width: 15375px) {
      width: 250px;
      height: 250px;
    }
    @media (max-width: 1055px) {
    }
    @media (max-width: 830px) {
      width: 220px;
      height: 200px;
    }
  }
}
