body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px rgba(255, 255, 255, 0);
  border: solid 3px transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px red;
  border: solid 3px transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-button {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
  height: 100%;
}
@media (max-width: 1000px) {
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px white;
    border: solid 3px transparent;
    border-radius: 10px;
  }
}
