.root {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  // background-color: aqua;
  display: flex;
  .footer_div {
    margin: auto;
    max-width: 1900px;
    width: 94%;
    height: 100%;
    background-color: white;
    border-top: thin solid lightgrey;
    font-family: nunito;
    display: flex;
    justify-content: space-around;

    @media (max-width: 1000px) {
      display: none;
      flex-direction: column;
      // display: block;
      justify-content: start;
      height: fit-content;
      // min-height: 300px;
      text-align: center;
      font-size: 14px;
    }
  }
}
