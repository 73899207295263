.root {
  width: 100%;
  height: fit-content;
  min-height: 300px;
  // background-color: aqua;
  display: flex;
  .order_div {
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    //background-color: red;
    min-height: 20px;
    @media (max-width: 1000px) {
      width: 90%;
    }

    justify-content: center;
    align-items: center;
    .link_to_main {
      color: white;
      width: fit-content;
      padding: 5px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 10px;
      background-color: rgb(219, 41, 41);
      margin: auto;
      //border-top: thin solid grey;
      margin-top: 40px;
      text-align: center;
      font-size: 25px;
      font-family: nunito;
      @media (max-width: 1000px) {
        font-size: 20px;
      }
    }
    p {
      text-align: center;
      font-size: 20px;
      font-family: nunito;
      @media (max-width: 1000px) {
        font-size: 18px;
      }
    }
  }
}
