.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  // height: 100px;
  // background-color: aqua;

  .dostavka_info_div {
    width: 50%;
    min-width: 1200px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    align-items: center;
    @media (max-width: 1200px) {
      min-width: 1000px;
      max-width: 1000px;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      min-width: 800px;
      max-width: 800px;
    }
    @media (max-width: 800px) {
      min-width: 100%;
    }

    .di_text_div {
      width: 50%;
      font-size: 20px;
      font-family: nunito;
      @media (max-width: 1000px) {
        font-size: 16px;
        width: 80%;
      }
      @media (max-width: 800px) {
        font-size: 16px;
        width: 90%;
      }
      p {
        span {
          color: rgb(219, 41, 41);
        }
      }
    }
    .di_img_div {
      width: 50%;
      @media (max-width: 800px) {
        width: 70%;
      }
      img {
        width: 100%;
      }
    }
  }
}
