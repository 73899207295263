.root {
  display: flex;
  margin-bottom: 60px;
  width: 100%;
  //border: thin solid grey;
  flex-direction: column;
  .title_LIP {
    width: 95%;
    height: 50%;
    margin-bottom: 20px;
    min-width: 300px;
    // background-color: blue;
    display: flex;
    div:first-child {
      display: flex;
      margin-top: 38px;
      background-color: rgb(219, 41, 41);
      width: 47%;
      height: 59px;
      z-index: -2;
      justify-content: end;
      @media (max-width: 2500px) {
        width: 40%;
      }
      @media (max-width: 1375px) {
        width: 40%;

        height: 50px;
      }
      @media (max-width: 1000px) {
        width: 45%;
        min-width: 260px;
        height: 40px;
      }
      @media (max-width: 500px) {
        // width: 75%;
        min-width: 260px;
        height: 40px;
      }
      p {
        font-size: 38px;
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 90px;
        font-family: 'Jura';
        font-weight: 500;
        @media (max-width: 1375px) {
          position: absolute;
          margin-right: 42px;
          font-size: 40px;
        }
        @media (max-width: 1000px) {
          position: absolute;
          margin: auto;
          margin-right: 35px;
          margin-top: 4px;
          font-size: 24px;
        }
      }
    }
    div:last-child {
      position: relative;
      margin-top: 47.1px;
      margin-left: -21px;
      background-color: white;
      border: thin solid rgb(219, 41, 41);
      width: 40.1px;
      height: 40.1px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: -1;
      @media (max-width: 1375px) {
        width: 34px;
        height: 34px;
        margin-top: 45px;
        margin-left: -18px;
      }
      @media (max-width: 1000px) {
        width: 26px;
        height: 26px;
        margin-top: 45px;
        margin-left: -14px;
      }
    }
  }
}
